import { COMMON_GET_WEBSITES_DATA, COMMON_CLEAR_STORE_DATA } from "~/actions/action-types";

export const GetWebsitesData = () => (dispatch) => {
	nanobar.go(50);
	return fetch(`https://marketing-api.healthmug.com/get-websites`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: COMMON_GET_WEBSITES_DATA, data });
			nanobar.go(100);
		})
		.catch((err) => {
			nanobar.go(100);
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
		});
};

export const CommonClearStoreData = () => ({ type: COMMON_CLEAR_STORE_DATA });
